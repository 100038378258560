import React, {useContext} from 'react';
import {StandaloneAccount} from "../../models/StandaloneAccount";
import {LegalAgreement} from "../../models/InvestmentProgram";
import {DeleteModalProps} from "../../common/AssetsView";
import AssetsViewContext from "../../common/AssetsViewContext";
import {AccountTableSection} from "./AccountTableSection";
import {DropResult} from "react-beautiful-dnd";
import {NO_OP} from "../../../constants/common";
import {sortByOrdinalAndInEstateOrMarketValue} from "../common/utils";
import {PartiallyOwnedInvestmentAccountReadModel} from "../../models/PartiallyOwnedInvestmentAccount";

export type AccountsTableProps = {
    legalAgreements: LegalAgreement[],
    standaloneAccounts: StandaloneAccount[],
    partiallyOwnedInvestmentAccounts: PartiallyOwnedInvestmentAccountReadModel[],
    profileId: string,
    onDeleteAssetClick: (deleteModalProps: DeleteModalProps) => void,
    hideActionMenu?: boolean,
    isDraggable?: boolean,
    captureAccountsOrder?: ({source, destination}: DropResult) => void
}

function AccountsTable({
                           profileId,
                           onDeleteAssetClick,
                           standaloneAccounts,
                           partiallyOwnedInvestmentAccounts,
                           legalAgreements,
                           hideActionMenu = false,
                           isDraggable = false,
                           captureAccountsOrder = NO_OP
                       }: AccountsTableProps) {

    const viewType = useContext(AssetsViewContext);

    const allAccounts = sortByOrdinalAndInEstateOrMarketValue([
        ...legalAgreements,
        ...standaloneAccounts,
        ...partiallyOwnedInvestmentAccounts
    ]);

    return <>
        <div role="table" className="assets-grid-table" aria-label="accounts-table">
            <div role="row"
                 className={`${hideActionMenu ? 'assets-grid-table-header_arrange' : 'assets-grid-table-header'} assets-grid-with-actionmenu`}
                 tabIndex={-1}>
                <span role="columnheader" className='textalign-left'>Name</span>
                <span role="columnheader" className='textalign-left'>Institution</span>
                <span role="columnheader" className='textalign-left'>Entity</span>
                <span role="columnheader" className='textalign-right'>Investable Value</span>
                <span role="columnheader" className='textalign-right'>Present Value</span>
            </div>

            {allAccounts.length > 0 &&
                <AccountTableSection
                    viewType={viewType}
                    accountsAndLegalAgreements={allAccounts}
                    profileId={profileId}
                    handleDelete={onDeleteAssetClick}
                    hideActionMenu={hideActionMenu}
                    isDraggable={isDraggable}
                    captureAccountsOrder={captureAccountsOrder}
                />
            }
        </div>
    </>;
}

export default AccountsTable;
