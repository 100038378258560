import {
    emptyPrimaryContact,
    emptyPrimaryContactForm,
    PrimaryContact,
    PrimaryContactForm,
    PrimaryContactValidation
} from "./PrimaryContact";
import {EffectiveTaxRateType, emptyEffectiveTaxRate} from "./EffectiveTaxRateType";
import {NewClientProfileValidationContainer} from "../NewClientProfile/NewClientProfileSlice";
import {AdvisorDocumentMenus} from "../../Resources/models/AdviceLibrary";
import {ServiceTeamMember} from "./ServiceTeam";

export enum ProfileType {
    PROPOSAL = "PROPOSAL",
    PROFILE = "PROFILE"
}

export type PartnerDashboardProfile = {
    id: string,
    displayName: string,
    createdDate: string,
    lastModifiedDate: string|null,
    lastModifiedByName: string,
    archived: boolean,
    prospect: boolean,
    lastMeetingEndDate: string|null,
    isActivePortfolioReserve: boolean,
    annualReportSentDate: string,
    activeClient: boolean,
    resourceCode: string|null
}

export type ProfileSettings = {
    displayName: string,
    effectiveTaxRate: EffectiveTaxRateType,
    managementFees: string
    accreditedStatus: string,
    allocationType: string,
}

export type ProfileRequest = ProfileSettings & {
    primaryContact: PrimaryContactForm,
    createdByName: string,
    lastModifiedByName: string,
    resourceCode: string,
    archived: boolean
    prospect: boolean,
    sample:boolean,
    portfolioReserveTargetLength?: number,
    isActivePortfolioReserve: boolean,
    estimatedEstateTaxValue?: number
}

export type ProspectRequest = {
    profile: ProfileRequest;
    serviceTeamMembers: ServiceTeamMember[];
}

export type SampleRequest = {
    profile: ProfileRequest;
}

export type ProfileResponse = ProfileRequest & {
    id: string,
    createdDate: string,
    lastModifiedDate: string | null,
    primaryContact: PrimaryContact,
    owner: string,
    profileIdForProposal?: string | null
    portfolioReserveActivationDate: string | null
    type: ProfileType
    activeClient: boolean,
    archived: boolean,
    estimatedEstateTaxValue?: number
}

export type ProspectResponse = {
    profile: ProfileResponse;
    serviceTeamMembers: ServiceTeamMember[];
}

export type SampleResponse = {
    profile: ProfileResponse;
}

export const validateProfileResponseContainer = (profile: ProfileRequest): NewClientProfileValidationContainer => {
    const newClientProfileFormValidation = {
        displayName: profile?.displayName ? null : "Display Name is required.",
        firstName: profile?.primaryContact?.firstName ? null : "First Name is required.",
        lastName: profile?.primaryContact?.lastName ? null : "Last Name is required.",
        age: profile?.primaryContact?.age ? null : "Age is required.",
        personalPlanningHorizon: profile?.primaryContact?.personalPlanningHorizon ? null : "Personal Planning Horizon is required."
    }

    const newClientProfileFormHasErrors = Object.values(newClientProfileFormValidation).some((validation) => validation !== null)

    return (
        {newClientProfileForm: newClientProfileFormHasErrors ? newClientProfileFormValidation : null}
    );
}

// TODO: Remove after refactoring ProfileSettings to use redux.
export const validateProfileResponse = (profile: ProfileRequest): ProfileRequestValidation => {
    const primaryContactValidation = {
        firstName: profile?.primaryContact?.firstName ? null : "First Name is required.",
        lastName: profile?.primaryContact?.lastName ? null : "Last Name is required.",
        age: profile?.primaryContact?.age ? null : "Age is required.",
        personalPlanningHorizon: profile?.primaryContact?.personalPlanningHorizon ? null : "Personal Planning Horizon is required."
    }

    const primaryContactHasErrors = Object.values(primaryContactValidation).some((validation) => validation !== null)

    return (
        {
            displayName: profile?.displayName ? null : "Display Name is required.",
            primaryContact: primaryContactHasErrors ? primaryContactValidation : null
        }
    );
}

export type ProfileRequestValidation = {
    displayName: null | string,
    primaryContact: null | PrimaryContactValidation,
}

export const emptyProfileSettings: ProfileSettings = {
    displayName: '',
    effectiveTaxRate: {...emptyEffectiveTaxRate},
    managementFees: '',
    accreditedStatus: '',
    allocationType: '',
};

export const emptyProfileRequest: ProfileRequest = {
    displayName: '',
    primaryContact: {...emptyPrimaryContactForm},
    effectiveTaxRate: {...emptyEffectiveTaxRate},
    managementFees: '',
    accreditedStatus: '',
    allocationType: '',
    createdByName: '',
    lastModifiedByName: '',
    resourceCode: '',
    archived: false,
    prospect: false,
    sample:false,
    isActivePortfolioReserve: false
}

export const emptyProfileResponse: ProfileResponse = {
    id: '',
    createdDate: '',
    lastModifiedDate: '',
    displayName: '',
    primaryContact: {...emptyPrimaryContact},
    effectiveTaxRate: {...emptyEffectiveTaxRate},
    managementFees: '',
    accreditedStatus: '',
    allocationType: '',
    createdByName: '',
    lastModifiedByName: '',
    resourceCode: '',
    owner: '',
    archived: false,
    prospect: false,
    sample:false,
    isActivePortfolioReserve: false,
    portfolioReserveActivationDate: '',
    type: ProfileType.PROFILE,
    activeClient: true
}

export const emptyProspectRequest: ProspectRequest = {
    profile: emptyProfileRequest,
    serviceTeamMembers: []
}

export const menuStructure: AdvisorDocumentMenus = {
    advisorDocumentSubMenus :[]
};
