import React, {useEffect, useMemo, useState} from 'react';
import {Col, Grid, Row} from "xps-react";
import {AlertMessage, Button, Name} from '../../components';
import {clientManagementApiClient} from "../ClientManagementApiClient";
import {useHistory, useParams} from "react-router-dom";
import {NewClientPrimaryContact} from "./NewClientPrimaryContact";
import {EffectiveTaxRates, taxRateWithDefaults} from "./EffectiveTaxRates";
import {referenceDataClient} from "../../core/ReferenceDataClient";
import {ReferenceDataType} from "../../models/referenceData/ReferenceDataType";
import {EffectiveFees} from './EffectiveFees';
import {validateProfileResponseContainer} from '../models/ProfileResponse';
import {useMsal} from "@azure/msal-react";
import {msalUtils} from "../../MsalUtils";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {clearPartnerWorkstationState, selectLinkedUlek} from "../PartnerWorkstation/partnerWorkstationSlice";
import {
    NewClientProfileValidationContainer,
    selectIsSaveButtonClicked,
    selectIsValidForm,
    selectNewClientFormInteractions,
    selectNewClientFormValidation,
    selectProfileRequestForm,
    setIsSaveButtonClicked,
    setIsValidForm,
    setNewClientFormInteractions,
    setNewClientFormValidation,
    setProfileRequestForm
} from "./NewClientProfileSlice";
import NewClientFinancialModel from "./NewClientFinancialModel";
import {useAppInsights} from "../../AppInsights";
import {MeetingParticipation} from "../Meeting/Meeting";
import {validateInputRemovingSpaces} from "../../utils/stringUtils";
import {RouteWithProfileType} from "../../routes/types";
import {ServiceTeamMember} from "../models/ServiceTeam";
import {partnerApiClient} from "../PartnerApiClient";
import {ProfileSettingsContent, ServiceTeamTab} from "../ProfileSettings/ProfileSettings";
import {NO_OP} from "../../constants/common";
import {Partner} from "../models/PartnerResource";

type NewClientProfileProps = {
    initialReferenceData?: ReferenceDataType,
}

export const defaultReferenceData: ReferenceDataType = {
    defaultPersonalPlanningHorizon: 100,
    statesOfResidency: [],
    taxRates: {
        federalTaxRateDefault: '',
        stateTaxRateDefault: '',
        capitalGainsPercentDefault: '',
        federalTaxRateMinimum: '',
        federalTaxRateMaximum: '',
        stateTaxRateMinimum: '',
        stateTaxRateMaximum: '',
        capitalGainsPercentMinimum: '',
        capitalGainsPercentMaximum: ''
    },
    managementFees: {
        managementFeePercentDefault: '',
        managementFeePercentMaximum: '',
        managementFeePercentMinimum: ''
    },
    accreditedStatus: {
        defaultValue: '',
        items: [],
    },
    allocationType: {
        defaultValue: '',
        items: [],
    }
};

const NewClientProfile: React.FC<NewClientProfileProps> = (props) => {
    const {
        initialReferenceData = defaultReferenceData,
    } = props;

    const history = useHistory();
    const msal = useMsal();
    // TODO: Refactor reference data to its own redux slice
    const [referenceData, updateReferenceData] = useState(initialReferenceData);
    const [accountName, updateAccountName] = useState('');
    const [serviceTeamMembers, setServiceTeamMembers] = useState<ServiceTeamMember[]>([]);

    const dispatch = useAppDispatch();
    const linkedUlek = useAppSelector(selectLinkedUlek)!;
    const profileRequestForm = useAppSelector(selectProfileRequestForm);
    const isSaveButtonClicked = useAppSelector(selectIsSaveButtonClicked)
    const {newClientProfileForm} = useAppSelector(selectNewClientFormValidation);
    const formInteractions = useAppSelector(selectNewClientFormInteractions);
    const isValidForm = useAppSelector(selectIsValidForm);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
    const appInsights = useAppInsights();
    const {type} = useParams<RouteWithProfileType>();

    const isProspect = useMemo(() => {
        return type.toUpperCase() === 'PROSPECT';
    }, [type]);

    const isSample = useMemo(() => {
        return type.toUpperCase() === 'SAMPLE';
    }, [type]);

    useEffect(() => {
        referenceDataClient.getReferenceData()
            .then((data: ReferenceDataType) => {
                updateReferenceData(data);
                dispatch(setProfileRequestForm({
                    ...profileRequestForm,
                    primaryContact: {
                        ...profileRequestForm.primaryContact,
                        personalPlanningHorizon: data.defaultPersonalPlanningHorizon
                    },
                    effectiveTaxRate: taxRateWithDefaults(profileRequestForm.effectiveTaxRate, data.taxRates),
                    managementFees: data.managementFees.managementFeePercentDefault,
                    accreditedStatus: data.accreditedStatus.defaultValue,
                    allocationType: data.allocationType.defaultValue,
                }))
            })
    }, []);

    useEffect(() => {
        checkFormIsValid();
    }, [profileRequestForm]);

    useEffect(() => {
        updateAccountName(msalUtils.getAccountName(msal)!);

        if (isProspect && msalUtils.getLanId(msal)) {
            partnerApiClient.getPartnerDetail(msalUtils.getLanId(msal)).then((serviceTeamMember) => {
                setServiceTeamMembers([serviceTeamMember]);
            })
        }
    }, [msal]);

    const checkFormIsValid = () => {
        const profileValidation: NewClientProfileValidationContainer = validateProfileResponseContainer(profileRequestForm);
        dispatch(setNewClientFormValidation(profileValidation));
        const isValid = Object.values(profileValidation).every(x => x === null);
        dispatch(setIsValidForm(isValid));
        return isValid;
    }

    const handleSaveButton = async () => {
        if (checkFormIsValid()) {
            if (saveButtonDisabled) {
                return;
            }
            setSaveButtonDisabled(true);

            const profileRequest = {
                ...profileRequestForm,
                prospect: isProspect,
                sample: isSample,
                createdByName: accountName,
                lastModifiedByName: accountName,
                resourceCode: linkedUlek
            };
            const prospectRequest = {
                profile: profileRequest,
                serviceTeamMembers
            };
            const sampleRequest = {
                profile: profileRequest
            }

            if (isProspect) {
                clientManagementApiClient.postProspect(prospectRequest).then((prospectResponse) => {
                    appInsights.trackEvent({
                        name: 'ProfileSettings',
                        properties:
                            {
                                screen: 'Profile Settings',
                                action: 'Save Button Click',
                                meetingStatus: MeetingParticipation.OUT_OF_MEETING,
                                data: 'Profile Created'
                            }
                    })

                    resetLinkedClient();
                    history.push({
                        pathname: `/Profile/${prospectResponse.profile.id}/ClientProfile/FamilyTree`
                    })
                })
            } else if (isSample) {
                clientManagementApiClient.postSample(sampleRequest).then((sampleResponse) => {
                    appInsights.trackEvent({
                        name: 'ProfileSettings',
                        properties:
                            {
                                screen: 'Profile Settings',
                                action: 'Save Button Click',
                                meetingStatus: MeetingParticipation.OUT_OF_MEETING,
                                data: 'Profile Created'
                            }
                    })

                    resetLinkedClient();
                    history.push({
                        pathname: `/Profile/${sampleResponse.profile.id}/ClientProfile/FamilyTree`
                    })
                })
            } else {
                clientManagementApiClient.postProfile(profileRequest).then(profileResponse => {
                    appInsights.trackEvent({
                        name: 'ProfileSettings',
                        properties:
                            {
                                screen: 'Profile Settings',
                                action: 'Save Button Click',
                                meetingStatus: MeetingParticipation.OUT_OF_MEETING,
                                data: 'Profile Created'
                            }
                    })

                    resetLinkedClient();
                    history.push({
                        pathname: `/Profile/${profileResponse.id}/ClientProfile/FamilyTree`
                    })

                }).catch((error) => {
                    console.error('Could not create profile', error.message);
                    setSaveButtonDisabled(false)
                })
            }
        } else {
            dispatch(setIsSaveButtonClicked(true))
        }
    };

    const resetLinkedClient = () => {
        dispatch(clearPartnerWorkstationState());
    }

    const profileTypeFormattedText = () => {
        switch (type.toUpperCase()) {
            case ('PROSPECT'):
                return {
                    header: 'Prospect',
                }
            case ('SAMPLE'):
                return {
                    header: 'Sample',
                }
            default:
                return {
                    header: 'Client',
                }
        }
    }

    const generalSettings = <Grid>
        <Row>
            <Col>
                <div className="h4">Profile Details</div>
                <hr className="section"/>
                <div className="parent">
                    <Name name={profileRequestForm.displayName}
                          label={"Display Name"}
                          onChange={(e: any) => dispatch(setProfileRequestForm({
                              ...profileRequestForm,
                              displayName: e.target.value && validateInputRemovingSpaces(e.target.value)
                          }))}
                          required={true}
                          error={newClientProfileForm?.displayName}
                          hasInteractions={formInteractions.displayName}
                          whenUserHasInteracted={() => {
                              dispatch(setNewClientFormInteractions({
                                  ...formInteractions,
                                  displayName: true
                              }))
                          }}
                          forceShowErrors={isSaveButtonClicked}/>
                </div>
                <NewClientPrimaryContact referenceData={referenceData}/>
            </Col>
            <Col>
                <EffectiveTaxRates taxRate={profileRequestForm.effectiveTaxRate}
                                   updateTaxRate={(taxRate) => {
                                       dispatch(setProfileRequestForm({
                                           ...profileRequestForm,
                                           effectiveTaxRate: taxRate
                                       }))
                                   }}
                                   referenceData={referenceData}/>
                <NewClientFinancialModel referenceData={referenceData}
                                         financialModel={{
                                             accreditedStatus: referenceData.accreditedStatus.defaultValue,
                                             allocationType: referenceData.allocationType.defaultValue,
                                         }}
                                         updateFinancialModel={(financialModel) => {
                                             dispatch(setProfileRequestForm({
                                                 ...profileRequestForm,
                                                 accreditedStatus: financialModel.accreditedStatus,
                                                 allocationType: financialModel.allocationType
                                             }))
                                         }
                                         }
                />
                <EffectiveFees managementFees={profileRequestForm.managementFees}
                               updateManagementFees={(managementFees) => {
                                   dispatch(setProfileRequestForm({
                                       ...profileRequestForm,
                                       managementFees: managementFees
                                   }))
                               }}
                               referenceData={referenceData}/>
            </Col>
        </Row>
    </Grid>;

    const serviceTeamSettings = <ServiceTeamTab
        serviceTeam={serviceTeamMembers}
        savedProfileOwner={msalUtils.getLanId(msal)}
        profileOwnerFormState={msalUtils.getLanId(msal)}
        updateProfileOwnerFormState={NO_OP}
        userLanID={msalUtils.getLanId(msal)}
        hasLinkedUlek={false}
        disabled={false}
        isProspect
        isProposal={false}
        onPartnerSelect={isProspect
            ? (partner: Partner) => {
                setServiceTeamMembers([...serviceTeamMembers, partner])
            }
            : NO_OP}
        onRemoveMember={isProspect
            ? (partnerCode: string) => {
                setServiceTeamMembers(serviceTeamMembers.filter((member) => member.partnerCode !== partnerCode))
            }
            : NO_OP}/>;

    return (
        <div className="new-client-profile-page">
            <div className="client-table">
                <Grid>
                    <Row>
                        <Col>
                            <span
                                className="font-xxl font-xxl">{`New ${profileTypeFormattedText().header} Profile`}</span>
                            <p className="font-default color-text--error noMargin">* Required fields</p>
                        </Col>
                        <Col>
                            <div className="textalign-right">
                                <Button className="marginright-md"
                                        icon="none"
                                        id="cancel_new_client_profile_button"
                                        includeRef={false}
                                        kind="secondary"
                                        onClick={() => {
                                            resetLinkedClient();
                                            history.push(`/Dashboard/${type}`)
                                        }}
                                        size="medium"
                                        tabIndex={0}
                                        type="button"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    icon="none"
                                    id="save_new_client_profile_button"
                                    includeRef={false}
                                    kind="primary"
                                    onClick={handleSaveButton}
                                    size="medium"
                                    tabIndex={0}
                                    type="button"
                                    disabled={saveButtonDisabled}
                                >
                                    SAVE
                                </Button>
                            </div>
                        </Col>
                        {!isValidForm && isSaveButtonClicked && (
                            <AlertMessage
                                className="display-flex justify-content-right alertBanner"
                                id="alertBanner"
                                fullWidth={false}
                                icon="warning"
                                showAlert={true}
                                showCloseBtn={false}
                                type="error"
                            >
                                <b className="alertBannerStyleName">Required fields missing</b>
                                &nbsp; &nbsp;
                                <b className="font-weight-400">All required fields must be entered to save a client
                                    profile.</b>
                            </AlertMessage>
                        )}
                    </Row>
                </Grid>
                <ProfileSettingsContent
                    showServiceTeamTab={type.toUpperCase() === 'PROSPECT' || type.toUpperCase() === 'SAMPLE'}
                    generalSettings={generalSettings}
                    serviceTeamSettings={serviceTeamSettings}/>
            </div>
        </div>
    );
};

export default NewClientProfile;
