export interface GoalFundingResponse {
    fundedByRiskControlAtYear: number[] | null;
    fundedByRiskAssetsAtYear: number[] | null;
    fundedByPortfolioAtYear: number[] | null;
    fundedByRiskControlPercentageAtYear: number[] | null;
    fundedByRiskAssetsPercentageAtYear: number[] | null;
    fundedByInflowsAtYear: number[] | null;
    fundedByNonInvestableAtYear: number[] | null;
    lifestyle: GoalFundingSummary;
    discretionary: GoalFundingSummary;
    philanthropic: GoalFundingSummary;
}

export interface GoalFundingSummary {
    fundedByInflowsAtYear?: number[] | null,
    fundedByNonInvestableAtYear?: number[] | null,
    fundedByRiskControlAtYear: number[] | null,
    fundedByPortfolioAtYear: number[] | null,
    fundedByRiskAssetsAtYear: number[] | null,
    fundedByRiskControlAtYearPercentage: number[] | null,
    fundedByRiskAssetsAtYearPercentage: number[] | null,
    presentValueAtYear: number[],
    goals: GoalFundingItem[]
}

export interface GoalFundingItem {
    id: string,
    description: string,
    fundedByInflowsAtYear?: number[] | null,
    fundedByNonInvestableAtYear?: number[] | null,
    fundedByRiskControlAtYear: number[] | null,
    fundedByPortfolioAtYear: number[] | null,
    fundedByRiskAssetsAtYear: number[] | null,
    fundedByRiskControlAtYearPercentage: number[] | null,
    fundedByRiskAssetsAtYearPercentage: number[] | null,
    presentValueAtYear: number[]
}

export enum DataDisplayView {
    PERCENTAGE_VIEW = 'Percentage View',
    DOLLAR_VIEW = 'Dollar View'
}

export enum FundingDisplayOptions {
    ONLY_PORTFOLIO,
    PORTFOLIO_AND_INFLOWS,
    PORTFOLIO_AND_NON_INVESTABLE,
    PORTFOLIO_AND_INFLOWS_AND_NON_INVESTABLE
}