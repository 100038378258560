import React, {Fragment} from "react";
import {ReserveTargetAllocationDonut} from "../ReserveTargetAllocationDonut";
import {useAppSelector} from "../../../store/hooks";
import {
    selectPortfolioReserveButtonStates,
} from "../PortfolioReserveSlice";

type AssetAllocationRowProps = {
    assetAllocationRowActive: boolean,
    reserveTargetAssetAllocation: { totalRiskAssetsPercent: number; totalRiskControlAssetsPercent: number },
    minimumRiskAssetAllocation: { totalRiskAssetsPercent: number; totalRiskControlAssetsPercent: number },
    riskControlDiffDesc: string
}

export const AssetAllocationRow = ({
                                       assetAllocationRowActive,
                                       reserveTargetAssetAllocation,
                                       minimumRiskAssetAllocation,
                                       riskControlDiffDesc
                                   }: AssetAllocationRowProps
) => {
    const portfolioReserveButtonStates = useAppSelector(selectPortfolioReserveButtonStates);
    const {assetAllocation} = portfolioReserveButtonStates;

    return (
        <div
            className={`edit-portfolio-reserve-page__table__row`}
            role="row">
            <div className="edit-portfolio-reserve-page__table__column">
                <div
                    className={`edit-portfolio-reserve-page__table__column__content ${assetAllocationRowActive ? "" : "row-inactive"}`}>
                    How does this Reserve Target impact my proposed asset allocation?
                </div>
            </div>
            <div
                className="edit-portfolio-reserve-page__table__column edit-portfolio-reserve-page__table__second-column edit-portfolio-reserve-page__centered">
                <ReserveTargetAllocationDonut
                    assetAllocationRowActive={assetAllocationRowActive}
                    reserveTargetAssetAllocation={reserveTargetAssetAllocation}/>
            </div>
            <div
                className="edit-portfolio-reserve-page__table__column edit-portfolio-reserve-page__table__third-column edit-portfolio-reserve-page__centered">
                <ReserveTargetAllocationDonut
                    assetAllocationRowActive={assetAllocationRowActive}
                    reserveTargetAssetAllocation={minimumRiskAssetAllocation}/>
                {assetAllocation ? <span className="edit-portfolio-reserve-page__text">{riskControlDiffDesc}</span> :
                    <Fragment/>}
            </div>
        </div>
    );
}