type App_Conf_Data = {
    API_URL: string;
    APIM_URL: string;
    APP_URL: string;
    RELAY_ENDPOINT_URL: string;
    RELAY_FUNCTION_URL: string;
    MEETING_PORTAL_AUTHENTICATOR_FN_URL: string;
    ACS_TEAMS_USER_TOKEN_PROVIDER_FN_URL: string;
    SPA_CONFIG_FN_URL: string;
    AUTHORITY: string;
};

enum Authorities {
    DEFAULT = "https://login.microsoftonline.com/2434528d-4270-4977-81dd-a6308c1761a3"
}

export enum LocationURIs {
    AZURE_DEV = "gpi.dev.northerntrust.com",
    AZURE_DEVQA1 = "gpi.qa1.dev.northerntrust.com",  // change to devaz and delete
    AZURE_DEVAZ = "gpi.devaz.northerntrust.com",
    AZURE_UAT = "gpi.uat.northerntrust.com",
    AZURE_UATAZ = "gpi.uataz.northerntrust.com",
    AZURE_QA = "gpi.qa.northerntrust.com",
    AZURE_QAAZ = "gpi.qaaz.northerntrust.com",
    AZURE_QAAZ_CUS = "gpi.cus.qa.northerntrust.com",
    AZURE_QAAZ_EU2 = "gpi.eu2.qa.northerntrust.com",
    AZURE_PROD = "gpi.northerntrust.com",
    AZURE_PROD_CUS = "gpi.cus.northerntrust.com",
    AZURE_PROD_EU2 = "gpi.eu2.northerntrust.com",
    AZURE_PRODAZ = "gpi.az.northerntrust.com",
    AZURE_PRODAZ_CUS = "gpi.cus.az.northerntrust.com",
    AZURE_PRODAZ_EU2 = "gpi.eu2.az.northerntrust.com",
    LOCAL = "localhost:8080",
    LOCAL_TEST = "localhost",
}

const appUrl = window.location.origin;
const apimUrl = `${appUrl}/apim`;
const apiUrl = `${apimUrl}/gateway/v1/api`;

let app_conf: App_Conf_Data = {
    API_URL: apiUrl,
    APIM_URL: apimUrl,
    APP_URL: appUrl,
    RELAY_FUNCTION_URL: '',
    ACS_TEAMS_USER_TOKEN_PROVIDER_FN_URL: '',
    MEETING_PORTAL_AUTHENTICATOR_FN_URL: '',
    RELAY_ENDPOINT_URL: 'https://us.fluidrelay.azure.com',
    AUTHORITY: Authorities.DEFAULT,
    SPA_CONFIG_FN_URL: ''
}

switch (window.location.host) {
    case LocationURIs.AZURE_PRODAZ:
        console.log('Configuring Azure PROD environment');
        app_conf = {
            ...app_conf,
            API_URL: `${app_conf.APIM_URL}/gateway/v1/api`,
            MEETING_PORTAL_AUTHENTICATOR_FN_URL: `${app_conf.APIM_URL}/meeting/v1/api/MeetingPortalAuthenticatorHttpTrigger`,
            SPA_CONFIG_FN_URL: `${app_conf.APIM_URL}/spa-config/v1/api/SpaConfigHttpTrigger`
        };
        break;
    case LocationURIs.AZURE_PRODAZ_CUS:
        console.log('Configuring Azure PROD_CUS environment');
        app_conf = {
            ...app_conf,
            API_URL: `${app_conf.APIM_URL}/gateway/v1/api`,
            MEETING_PORTAL_AUTHENTICATOR_FN_URL: `${app_conf.APIM_URL}/meeting/v1/api/MeetingPortalAuthenticatorHttpTrigger`,
            SPA_CONFIG_FN_URL: `${app_conf.APIM_URL}/spa-config/v1/api/SpaConfigHttpTrigger`
        };
        break;
    case LocationURIs.AZURE_PRODAZ_EU2:
        console.log('Configuring Azure PROD_EU2 environment')
        app_conf = {
            ...app_conf,
            API_URL: `${app_conf.APIM_URL}/gateway/v1/api`,
            MEETING_PORTAL_AUTHENTICATOR_FN_URL: `${app_conf.APIM_URL}/meeting/v1/api/MeetingPortalAuthenticatorHttpTrigger`,
            SPA_CONFIG_FN_URL: `${app_conf.APIM_URL}/spa-config/v1/api/SpaConfigHttpTrigger`
        };
        break;
    case LocationURIs.AZURE_PROD:
        console.log('Configuring Azure PRODAZ environment');
        app_conf = {
            ...app_conf,
            API_URL: `${app_conf.APIM_URL}/gatewayaks/v1/api`,
            MEETING_PORTAL_AUTHENTICATOR_FN_URL: `${app_conf.APIM_URL}/meetingaz/v1/api/MeetingPortalAuthenticatorHttpTrigger`,
            SPA_CONFIG_FN_URL: `${app_conf.APIM_URL}/spa-configaz/v1/api/SpaConfigHttpTrigger`
        };
        break;
    case LocationURIs.AZURE_PROD_CUS:
        console.log('Configuring Azure PRODAZ_CUS environment');
        app_conf = {
            ...app_conf,
            API_URL: `${app_conf.APIM_URL}/gatewayaks/v1/api`,
            MEETING_PORTAL_AUTHENTICATOR_FN_URL: `${app_conf.APIM_URL}/meetingaz/v1/api/MeetingPortalAuthenticatorHttpTrigger`,
            SPA_CONFIG_FN_URL: `${app_conf.APIM_URL}/spa-configaz/v1/api/SpaConfigHttpTrigger`
        };
        break;
    case LocationURIs.AZURE_PROD_EU2:
        console.log('Configuring Azure PRODAZ_EU2 environment')
        app_conf = {
            ...app_conf,
            API_URL: `${app_conf.APIM_URL}/gatewayaks/v1/api`,
            MEETING_PORTAL_AUTHENTICATOR_FN_URL: `${app_conf.APIM_URL}/meetingaz/v1/api/MeetingPortalAuthenticatorHttpTrigger`,
            SPA_CONFIG_FN_URL: `${app_conf.APIM_URL}/spa-configaz/v1/api/SpaConfigHttpTrigger`
        };
        break;
    case LocationURIs.AZURE_DEV:
        console.log('Configuring Azure DEVAZ environment');
        app_conf = {
            ...app_conf,
            API_URL: `${app_conf.APIM_URL}/gatewayaks/v1/api`,
            MEETING_PORTAL_AUTHENTICATOR_FN_URL: `${app_conf.APIM_URL}/meetingaz/v1/api/MeetingPortalAuthenticatorHttpTrigger`,
            SPA_CONFIG_FN_URL: `${app_conf.APIM_URL}/spa-configaz/v1/api/SpaConfigHttpTrigger`
        };
        break;
    case LocationURIs.AZURE_DEVAZ:
        console.log('Configuring Azure DEV environment');
        app_conf = {
            ...app_conf,
            API_URL: `${app_conf.APIM_URL}/gateway/v1/api`,
            MEETING_PORTAL_AUTHENTICATOR_FN_URL: `${app_conf.APIM_URL}/meeting/v1/api/MeetingPortalAuthenticatorHttpTrigger`,
            SPA_CONFIG_FN_URL: `${app_conf.APIM_URL}/spa-config/v1/api/SpaConfigHttpTrigger`
        };
        break;
    case LocationURIs.AZURE_DEVQA1:
        console.log('Configuring Azure DEV QA1 environment');
        app_conf = {
            ...app_conf,
            API_URL: `${app_conf.APIM_URL}/gateway/v1/api`,
            MEETING_PORTAL_AUTHENTICATOR_FN_URL: `${app_conf.APIM_URL}/meeting/v1/api/MeetingPortalAuthenticatorHttpTrigger`,
            SPA_CONFIG_FN_URL: `${app_conf.APIM_URL}/spa-config/v1/api/SpaConfigHttpTrigger`
        };
        break;
    case LocationURIs.AZURE_QAAZ:
        console.log('Configuring Azure QA environment')
        app_conf = {
            ...app_conf,
            API_URL: `${app_conf.APIM_URL}/gateway/v1/api`,
            MEETING_PORTAL_AUTHENTICATOR_FN_URL: `${app_conf.APIM_URL}/meeting/v1/api/MeetingPortalAuthenticatorHttpTrigger`,
            SPA_CONFIG_FN_URL: `${app_conf.APIM_URL}/spa-config/v1/api/SpaConfigHttpTrigger`
        };
        break;
    case LocationURIs.AZURE_QA:
        console.log('Configuring Azure QAAZ environment')
        app_conf = {
            ...app_conf,
            API_URL: `${app_conf.APIM_URL}/gatewayaks/v1/api`,
            MEETING_PORTAL_AUTHENTICATOR_FN_URL: `${app_conf.APIM_URL}/meetingaz/v1/api/MeetingPortalAuthenticatorHttpTrigger`,
            SPA_CONFIG_FN_URL: `${app_conf.APIM_URL}/spa-configaz/v1/api/SpaConfigHttpTrigger`
        };
        break;
    case LocationURIs.AZURE_QAAZ_CUS:
        console.log('Configuring Azure QAAZ_CUS environment')
        app_conf = {
            ...app_conf,
            API_URL: `${app_conf.APIM_URL}/gatewayaks/v1/api`,
            MEETING_PORTAL_AUTHENTICATOR_FN_URL: `${app_conf.APIM_URL}/meetingaz/v1/api/MeetingPortalAuthenticatorHttpTrigger`,
            SPA_CONFIG_FN_URL: `${app_conf.APIM_URL}/spa-configaz/v1/api/SpaConfigHttpTrigger`
        };
        break;
    case LocationURIs.AZURE_QAAZ_EU2:
        console.log('Configuring Azure QAAZ EU2 environment')
        app_conf = {
            ...app_conf,
            API_URL: `${app_conf.APIM_URL}/gatewayaks/v1/api`,
            MEETING_PORTAL_AUTHENTICATOR_FN_URL: `${app_conf.APIM_URL}/meetingaz/v1/api/MeetingPortalAuthenticatorHttpTrigger`,
            SPA_CONFIG_FN_URL: `${app_conf.APIM_URL}/spa-configaz/v1/api/SpaConfigHttpTrigger`
        };
        break;
    case LocationURIs.AZURE_UATAZ:
        console.log('Configuring Azure UAT environment')
        app_conf = {
            ...app_conf,
            API_URL: `${app_conf.APIM_URL}/gateway/v1/api`,
            MEETING_PORTAL_AUTHENTICATOR_FN_URL: `${app_conf.APIM_URL}/meeting/v1/api/MeetingPortalAuthenticatorHttpTrigger`,
            SPA_CONFIG_FN_URL: `${app_conf.APIM_URL}/spa-config/v1/api/SpaConfigHttpTrigger`
        };
        break;
    case LocationURIs.AZURE_UAT:
        console.log('Configuring Azure UATAZ environment')
        app_conf = {
            ...app_conf,
            API_URL: `${app_conf.APIM_URL}/gatewayaks/v1/api`,
            MEETING_PORTAL_AUTHENTICATOR_FN_URL: `${app_conf.APIM_URL}/meetingaz/v1/api/MeetingPortalAuthenticatorHttpTrigger`,
            SPA_CONFIG_FN_URL: `${app_conf.APIM_URL}/spa-configaz/v1/api/SpaConfigHttpTrigger`
        };
        break;
    default:
        app_conf = {
            ...app_conf,
            API_URL: 'http://localhost:8081',
            RELAY_FUNCTION_URL: 'https://gpi-fluid-token-provider-cus-d-funcapp.azurewebsites.net/api/FluidTokenProviderHttpTrigger?code=PUmXpCOIrELZ1PwC49rXeR7Javy0kyAPMS2OL9-LxKnqAzFusLsAIQ==',
            MEETING_PORTAL_AUTHENTICATOR_FN_URL: 'https://gpi-meeting-authenticator-cus-d-funcapp.azurewebsites.net/api/MeetingPortalAuthenticatorHttpTrigger',
            ACS_TEAMS_USER_TOKEN_PROVIDER_FN_URL: 'https://gpi-fluid-token-provider-cus-d-funcapp.azurewebsites.net/api/ACSTeamsUserTokenProviderHttpTrigger',
            SPA_CONFIG_FN_URL: 'https://gpi-spa-config-cus-d-funcapp.azurewebsites.net/api/SpaConfigHttpTrigger'
        };
        break;
}

if (process.env.REACT_APP_API_URL !== undefined) {
    console.log('Reconfiguring API_URL to:');
    console.log(process.env.REACT_APP_API_URL);
    app_conf = {...app_conf, API_URL: process.env.REACT_APP_API_URL};
}

if (window.location.host !== LocationURIs.LOCAL) {
    app_conf = {
        ...app_conf,
        RELAY_FUNCTION_URL: `${app_conf.API_URL}/FluidTokenProviderHttpTrigger`,
        ACS_TEAMS_USER_TOKEN_PROVIDER_FN_URL: `${app_conf.API_URL}/ACSTeamsUserTokenProviderHttpTrigger`,
    }
}

export const isLocalGateway = () => {
    return app_conf.API_URL === 'http://localhost:8081';
};

export const App_Conf = app_conf;
