import React from "react";
import {DataDisplayView, FundingDisplayOptions, GoalFundingItem, GoalFundingSummary} from "./models/Funding";
import { FundingAccordionItemHeader } from "./FundingAccordionItemHeader";
import { AccordionItem } from "src/components";
import {
    formatFundingCurrency, formatFundingPercentage,
    isDollarView,
    isShowFundingForInflows,
    isShowFundingForNonInvestable,
    isShowOnlyPortfolio
} from "./fundingUtils";

type FundingTableSummaryProps = {
    goalsSummary: GoalFundingSummary;
    ageIndex: number;
    title: string;
    color: string;
    displayOptions: FundingDisplayOptions;
    displayView: DataDisplayView;
};

const FundingTableSummary: React.FC<FundingTableSummaryProps> = ({
    goalsSummary,
    ageIndex,
    title,
    color,
    displayOptions,
    displayView
}) => {
    const dollarView = isDollarView(displayView);
    if(!goalsSummary.goals.length) {
        return <></>
    }
    return (
        <AccordionItem
            uuid={title}
            accentColor={color}
            HeaderComponent={({expanded, toggle}) =>
                <FundingAccordionItemHeader
                    title={title}
                    expanded={expanded}
                    fundedByRiskAssets={dollarView
                        ? formatFundingCurrency(goalsSummary.fundedByRiskAssetsAtYear?.[ageIndex])
                        : formatFundingPercentage(goalsSummary.fundedByRiskAssetsAtYearPercentage?.[ageIndex])}
                    fundedByRiskControl={dollarView
                        ? formatFundingCurrency(goalsSummary.fundedByRiskControlAtYear?.[ageIndex])
                        : formatFundingPercentage(goalsSummary.fundedByRiskControlAtYearPercentage?.[ageIndex])}
                    fundedByPortfolio={formatFundingCurrency(goalsSummary.fundedByPortfolioAtYear?.[ageIndex])}
                    fundedByNonInvestable={formatFundingCurrency(goalsSummary.fundedByNonInvestableAtYear?.[ageIndex])}
                    fundedByInflows={formatFundingCurrency(goalsSummary.fundedByInflowsAtYear?.[ageIndex])}
                    presentValue={formatFundingCurrency(goalsSummary.presentValueAtYear?.[ageIndex])}
                    gridClassName="funding-grid"
                    toggle={toggle}
                    displayOptions={displayOptions}
                />
        }
        >
            <GoalFundingTable
                goals={goalsSummary.goals}
                ageIndex={ageIndex}
                goalType={title}
                displayOptions={displayOptions}
                dollarView={dollarView}
            />
        </AccordionItem>
    );
};

type GoalFundingTableProps = {
    goals: GoalFundingItem[];
    ageIndex: number;
    goalType: string;
    displayOptions: FundingDisplayOptions;
    dollarView: boolean;
};

const GoalFundingTable: React.FC<GoalFundingTableProps> = ({
    goals,
    ageIndex,
    goalType,
    displayOptions,
    dollarView
}) => {
    return (
        <div className="funding-table" role="table" aria-label={`${goalType} Table`}>
            {goals.map((goal) => {
                return (
                    <div
                        role="row"
                        className="funding-grid funding-table-row"
                        key={goal.description}
                    >
                        <span role="cell" className="font-md paddingleft-xxxl" aria-label="Goal Description">{goal.description}</span>
                        <span role="cell" className="font-md textalign-right" aria-label="Funded by Risk Assets">
                            {dollarView? formatFundingCurrency(goal.fundedByRiskAssetsAtYear?.[ageIndex]) : formatFundingPercentage(goal.fundedByRiskAssetsAtYearPercentage?.[ageIndex])}
                        </span>
                        <span role="cell" className="font-md textalign-right" aria-label="Funded by Risk Control">
                            {dollarView? formatFundingCurrency(goal.fundedByRiskControlAtYear?.[ageIndex]) : formatFundingPercentage(goal.fundedByRiskControlAtYearPercentage?.[ageIndex])}
                        </span>
                        {!isShowOnlyPortfolio(displayOptions) && <span role="cell" className="font-md textalign-right" aria-label="Funded by Portfolio">
                            {formatFundingCurrency(goal.fundedByPortfolioAtYear?.[ageIndex])}
                        </span>}
                        {isShowFundingForInflows(displayOptions) &&
                            <span role="cell" className="font-md textalign-right" aria-label="Funded by Inflows">
                                {formatFundingCurrency(goal.fundedByInflowsAtYear?.[ageIndex])}
                            </span>
                        }
                        {isShowFundingForNonInvestable(displayOptions) &&
                            <span role="cell" className="font-md textalign-right" aria-label="Funded by Non Investable">
                                {formatFundingCurrency(goal.fundedByNonInvestableAtYear?.[ageIndex])}
                            </span>
                        }
                        <span role="cell" className="font-md textalign-right" aria-label="Present Value">
                            {formatFundingCurrency(goal.presentValueAtYear?.[ageIndex])}
                        </span>
                        <span />
                    </div>
                )}
            )}
        </div>
    );
};

export default FundingTableSummary;
